<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">Crowdfunding User</h1>

    <div v-if="joinedUser" class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Email</p>
                <h2 class="headline--md mt-4">{{ joinedUser.user.email }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Full Name</p>
                <h2 class="headline--md mt-4">{{ joinedUser.user.fullName }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Wallet Address</p>
                <h2 class="headline--md mt-4">{{ joinedUser.walletAddress }}</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Deposited Amount ({{ joinedUser.token.symbol }})</p>
                <h2 class="headline--md mt-4">{{ joinedUser.depositedAmount }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Withdraw Amount ({{ joinedUser.token.symbol }})</p>
                <h2 class="headline--md mt-4">{{ joinedUser.withdrawAmount }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Rate (%)</p>
                <h2 class="headline--md mt-4">{{ joinedUser.rate | rate_percent }}</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Start Date</p>
                <h2 class="headline--md mt-4">{{ joinedUser.startAtMilliseconds | moment_lts }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">End Date</p>
                <h2 class="headline--md mt-4">{{ joinedUser.endAtMilliseconds | moment_lts }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Status</p>
                <h2 v-html="formatStakingStatus(joinedUser.status)" class="headline--md mt-4" />
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Deposit Tnx.Hash</p>
                <div class="clipboard-copy">
                  <h2
                    v-if="joinedUser.token.symbol.toUpperCase() === 'BTC'"
                    class="headline--md mt-4 clipboard-copy__text"
                  >
                    <span class="clipboard-copy__text-inner">{{ joinedUser.tnxHashDeposited }}</span>
                    <copy-clipboard
                      @on-copy="doCopy($event)"
                      :external-link="`/blockcypher/${joinedUser.tnxHashDeposited}`"
                    />
                  </h2>
                  <h2 v-else class="headline--md mt-4 clipboard-copy__text">
                    <span class="clipboard-copy__text-inner">{{ joinedUser.tnxHashDeposited }}</span>
                    <copy-clipboard
                      @on-copy="doCopy($event)"
                      :external-link="`/etherscan/${joinedUser.tnxHashDeposited}`"
                    />
                  </h2>
                </div>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Withdraw Tnx.Hash</p>
                <div class="clipboard-copy">
                  <h2
                    v-if="joinedUser.token.symbol.toUpperCase() === 'BTC'"
                    class="headline--md mt-4 clipboard-copy__text"
                  >
                    <span class="clipboard-copy__text-inner">{{ joinedUser.tnxHashWithdrew }}</span>
                    <copy-clipboard
                      @on-copy="doCopy($event)"
                      :external-link="`/blockcypher/${joinedUser.tnxHashWithdrew}`"
                    />
                  </h2>
                  <h2 v-else class="headline--md mt-4 clipboard-copy__text">
                    <span class="clipboard-copy__text-inner">{{ joinedUser.tnxHashWithdrew }}</span>
                    <copy-clipboard
                      @on-copy="doCopy($event)"
                      :external-link="`/etherscan/${joinedUser.tnxHashWithdrew}`"
                    />
                  </h2>
                </div>
              </div>
            </td>
            <td v-if="joinedUser.user.blacklist">
              <p class="statistic__title text-warm">Black List</p>
              <h2 class="headline--md mt-4 text-uppercase text-bold text-error">
                {{ joinedUser.user.blacklist | format_blacklist_type }}
              </h2>
            </td>
            <td v-else />
          </tr>
        </tbody>
      </table>
    </div>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import stakingStatus from '@/mixins/staking-status'
import toggleLoading from '@/mixins/toggle-loading'
import copyClipboard from '@/mixins/copy-clipboard'
const CopyClipboard = () => import('@/components/CopyClipboard')

export default {
  mixins: [stakingStatus, toggleLoading, copyClipboard],

  components: {
    CopyClipboard
  },

  computed: {
    ...mapState('crowdfunding', ['joinedUser'])
  },

  methods: {
    ...mapActions('crowdfunding', ['getCrowdfundingUser']),

    ...mapMutations('crowdfunding', ['CLEAR_CROWDFUNDING_JOINED_USER'])
  },

  created() {
    this.CLEAR_CROWDFUNDING_JOINED_USER()
    this.getCrowdfundingUser({ orderId: this.$route.params.id }).finally(() => {
      this.toggleLoading()
    })
  }
}
</script>
